import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export interface StyleProps {
  width?: number | 'auto';
  bordered?: boolean;
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      minWidth: ({ width }) => width || 100,
      overflow: 'hidden',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      height: 48,
      padding: '16px 24px',
      borderRadius: 24,
      borderWidth: 2,
      borderStyle: 'solid',
      lineHeight: '16px',
      marginLeft: 5,
      marginRight: 5,
      transition: 'all ease-in-out 0.2s',
      '&:hover': {
        boxShadow: 'none',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: colorVariables.transparent,
        transition: 'all ease-in-out 0.2s',
      },
      '&:active': {
        boxShadow: 'none',
        borderWidth: 2,
        borderStyle: 'solid',
        transition: 'all ease-in-out 0.2s',
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.5,
        transition: 'all ease-in-out 0.2s',
      },
    },
    primary: {
      borderColor: colorVariables.transparent,
      borderWidth: 2,
      color: ({ textColor }) => textColor || colorVariables.white,
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.green,
      '&:hover': {
        borderColor: colorVariables.transparent,
        borderWidth: 2,
        filter: 'brightness(0.85)',
        color: ({ textColor }) => textColor || colorVariables.white,
        backgroundColor: ({ bgColor }) => bgColor || colorVariables.green,
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.6,
        color: ({ textColor }) => textColor || colorVariables.white,
      },
    },
    primaryBordered: {
      borderColor: ({ textColor }) => textColor || colorVariables.green,
      borderWidth: 1,
      color: ({ textColor }) => textColor || colorVariables.green,
      backgroundColor: colorVariables.white,
      '&:hover': {
        borderColor: ({ textColor }) => textColor || colorVariables.green,
        borderWidth: 1,
        color: ({ textColor }) => textColor || colorVariables.green,
        backgroundColor: ({ bgColor }) => bgColor || colorVariables.greenLight,
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.6,
        color: ({ textColor }) => textColor || colorVariables.green,
      },
    },
    secondary: {
      borderColor: colorVariables.transparent,
      borderWidth: 2,
      color: ({ textColor }) => textColor || colorVariables.navy,
      backgroundColor: ({ bgColor }) => bgColor || 'rgba(152, 165, 183, 0.3)',
      '&:hover': {
        borderColor: colorVariables.transparent,
        borderWidth: 2,
        filter: 'brightness(0.85)',
        color: ({ textColor }) => textColor || colorVariables.navy,
        backgroundColor: ({ bgColor }) => bgColor || 'rgba(152, 165, 183, 0.5)',
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.6,
        color: ({ textColor }) => textColor || colorVariables.navy,
      },
    },
    secondaryBordered: {
      borderColor: ({ textColor }) => textColor || colorVariables.grey40,
      borderWidth: 1,
      color: ({ textColor }) => textColor || colorVariables.navy,
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.white,
      '&:hover': {
        borderColor: ({ bgColor }) => bgColor || colorVariables.grey40,
        borderWidth: 1,
        color: ({ bgColor }) => bgColor || colorVariables.white,
        backgroundColor: ({ textColor }) => textColor || colorVariables.grey40,
      },
      '&:disabled, &:hover:disabled': {
        opacity: 0.6,
        color: ({ bgColor }) => bgColor || colorVariables.grey40,
      },
    },
    white: {
      borderColor: ({ bordered }) => (bordered ? colorVariables.navy : colorVariables.white),
      borderWidth: ({ bordered }) => (bordered ? 1 : 2),
      color: colorVariables.navy,
      backgroundColor: colorVariables.white,
      '&:hover': {
        backgroundColor: colorVariables.grey20,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.white,
        },
      },
      '&:active': {
        backgroundColor: colorVariables.grey20,
      },
      '&:disabled, &:hover:disabled': {
        backgroundColor: colorVariables.grey10,
        borderColor: colorVariables.grey10,
      },
    },
    icon: {
      backgroundColor: colorVariables.transparent,
      borderColor: colorVariables.transparent,
      color: colorVariables.navy,
      padding: '16px 10px',
      fontSize: 14,
    },
    blueButton: {
      borderColor: colorVariables.transparent,
      color: colorVariables.white,
      backgroundColor: colorVariables.blue,
      '&:hover': {
        backgroundColor: colorVariables.blue,
        opacity: 0.7,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.blue,
        },
      },
      '&:active': {
        backgroundColor: colorVariables.blue,
      },
      '&:disabled, &:hover:disabled': {
        color: colorVariables.white,
        backgroundColor: colorVariables.blue,
        opacity: 0.4,
      },
    },
    smallButton: {
      fontSize: 14,
      fontWeight: 600,
      height: 34,
      lineHeight: '34px',
      padding: '0 24px',
      borderRadius: 17,
    },
    blueTransparent: {
      fontWeight: 600,
      color: colorVariables.blue,
      cursor: 'pointer',
      height: 36,
      lineHeight: '36px',
      padding: '16px 10px',
      width: ({ width }) => width || 'auto',
      textAlign: 'center',
      textTransform: 'none',
      borderRadius: 18,
      border: 'none',
      fontSize: 14,
      '&:hover': {
        border: 'none',
        backgroundColor: colorVariables.blueLight,
        [theme.breakpoints.down(Sizes.desktop)]: {
          backgroundColor: colorVariables.blueLight,
        },
      },
    },
  }),
);
