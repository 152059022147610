import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TokenService } from '../shared/services/token.service';
import store from '../store';

export const tagTypes = [
  'Me',
  'Customer',
  'Price_List',
  'Product',
  'Address',
  'Company',
  'Connection',
  'Supplier',
  'Cart',
  'Sub_Category',
  'Price_Exception',
  'Order',
  'Pantry_List',
  'Rec_Order',
  'Customers_Count',
  'Orders_Count',
  'Order_By_Id',
  'Products_Purchased',
  'Postcode',
  'Cart_Delivery',
  'Users',
  'Restaurants',
  'Suppliers',
  'Emails',
  'Controls',
  'Exports',
  'Dashboard',
  'Errors',
  'Delivery_List',
  'Drivers',
  'Menu',
  'Recipe',
  'Recipes',
  'Inventories',
  'Run_Out_Product',
  'Inventory_Storage',
  'Sales',
  'Wastages',
  'StockTake',
  'Pay_method',
  'Email_Controls',
  'Admin_Orders',
  'Admin_connection_graph',
  'Admin_res_graph',
  'Admin_sup_graph',
  'Admin_top_per_res',
  'Admin_top_per_sup',
  'Admin_risk_res',
  'Admin_risk_sup',
  'Admin_not_order',
  'Admin_overdue_order',
  'Teammate',
  'Discounts',
  'Manual_Product',
  'Country_Data',
  'Manual_Template',
  'Theme',
  'Prep_List',
  'Prep_Lists',
];

export const rootApi = createApi({
  reducerPath: 'API',
  tagTypes,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const token = TokenService.getToken();
      const adminCountry = store.getState().user.adminCountryCode;
      if (store.getState().user.isAdmin && adminCountry) {
        headers.set('Country-Code', adminCountry);
      }
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }) as BaseQueryFn,
  endpoints: () => ({}),
});

export type BaseQueryFn<
  Args = any,
  Result = unknown,
  Error = { data: { message: string; success: boolean }; status: number },
  DefinitionExtraOptions = {},
  Meta = {},
> = (args: Args, api: BaseQueryApi, extraOptions: DefinitionExtraOptions) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

export interface SuccessResponse {
  success: boolean;
  message: string;
}

export enum HTTP {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface PaginationResult {
  current_page: number;
  per_page: number;
  previous_page: null | number;
  next_page: null | number;
  total_pages: number;
}
