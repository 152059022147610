import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useLazyContactSalesQuery } from '../../../../api/company';
import { useGeIsTeammateAdmin } from '../../../../api/teammates/hooks';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import logo from '../../../../assets/images/logo/Logo-Modal.svg';
import subscribe from '../../../../assets/images/subscribe.png';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { OverlayModal } from '../../../../shared/components/overlay-modal';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppSelector } from '../../../../store';
import { getIsRestaurant, getMySubscriptionType } from '../../../../store/user';
import { useRSPricing } from '../../../../shared/hooks/useRSPricing';

import { useStyles } from './style';

export const Subscriptions: React.FC = () => {
  const classes = useStyles();
  const telLink = useRef<HTMLAnchorElement | null>(null);
  const mailLink = useRef<HTMLAnchorElement | null>(null);
  const { isMobile } = useScreenSize();
  const { push } = useHistory();

  const { rps, mc, currency } = useRSPricing();
  const isUpgradeDisabled = currency !== '$';

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAdminTeammate = useGeIsTeammateAdmin();
  const isViewer = !isAdminTeammate;

  const [isContactModalShown, setIsContactModalShown] = useState(false);

  const [contactSales] = useLazyContactSalesQuery();
  const subscriptionPlan = useAppSelector(getMySubscriptionType);
  const isUnlimited = subscriptionPlan === SubscriptionPlanEnum.UNLIMITED;

  const onPhoneClick = () => {
    telLink?.current?.click();
  };

  const onMailClick = () => {
    mailLink?.current?.click();
  };

  const onContactSales = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    if (isRestaurant) {
      return;
    }
    contactSales();
    setIsContactModalShown(true);
  };

  const openMenuPurchase = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push('/menu_subscribe_in_app');
  };

  const openProSuitePurchase = () => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    push('/pro_suite_subscribe_in_app');
  };

  return isRestaurant ? (
    <div className={clsx(classes.root)}>
      <div className={classes.planBox}>
        <div className={classes.planBoxContent}>
          <h3 className={classes.planName}>Standard</h3>
          <h4 className={clsx(classes.planSubTitle, classes.planSubTitleExtraMargin)}>Free</h4>
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>Access to:</h4>
          <p className={classes.benefitsItem}>Unlimited Ordering</p>
          <p className={classes.benefitsItem}>Unlimited Suppliers</p>
          <p className={classes.benefitsItem}>Automatic Order Reminders</p>
          <p className={classes.benefitsItem}>Notifications for Back-Orders</p>
          <p className={classes.benefitsItem}>Combine orders</p>
          <p className={classes.benefitsItem}>Predictive Order Reminder</p>
        </div>
        <ThemedButton onClick={onContactSales} title={`You're Welcome`} buttonStyle='blueButton' width={isMobile ? 268 : 224} />
      </div>
      <div className={clsx(classes.planBox, classes.mostPopularBoxBg)}>
        <div className={classes.mostPopularLabel}>MOST POPULAR</div>
        <div className={classes.planBoxContent}>
          <h3 className={classes.planName}>Menu Costing</h3>
          <div
            className={clsx(
              classes.planSubTitle,
              classes.planSubTitlePrice,
              classes.planSubTitlePriceLower,
              classes.planSubTitleExtraMargin,
            )}
          >
            {/*{`${planCostMC.currencySymbol}${planCostMC.monthlyCost} / Month`}*/}
            {`${currency} ${mc} / One-time Fee`}
          </div>
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>All the Benefits of Free, and:</h4>
          <p className={classes.benefitsItem}>Menu Planning Tool</p>
          <p className={classes.benefitsItem}>Create Unlimited Recipes</p>
          <p className={classes.benefitsItem}>Live Food & Beverage Profits/Costs</p>
          <p className={classes.benefitsItem}>Recipe Multiplier Planner</p>
          <p className={classes.benefitsItem}>Teammates</p>
        </div>
        <ThemedButton
          onClick={
            subscriptionPlan === SubscriptionPlanEnum.MENU || subscriptionPlan === SubscriptionPlanEnum.HOSPO ? () => {} : openMenuPurchase
          }
          title={subscriptionPlan === SubscriptionPlanEnum.MENU ? 'Current Plan' : `Upgrade`}
          customClass={classes.contactBtn}
          disabled={subscriptionPlan === SubscriptionPlanEnum.MENU || subscriptionPlan === SubscriptionPlanEnum.HOSPO || isUpgradeDisabled}
        />
      </div>
      <div className={classes.planBox}>
        <div className={classes.planBoxContent}>
          <h3 className={clsx(classes.planName)}>
            Restaurant Pro <br /> Suite
          </h3>
          <div className={clsx(classes.planSubTitle, classes.planSubTitlePrice, classes.planSubTitlePriceLower)}>
            {/*{`${planCostRPS.currencySymbol}${planCostRPS.monthlyCost} / Month`}*/}
            {`${currency} ${rps} / Month`}
          </div>
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>All the Benefits of Free, and Menu Costing and:</h4>
          <p className={classes.benefitsItem}>Track the daily profits/sales/costs of your restaurant</p>
          <p className={classes.benefitsItem}>Automated Inventory/Stocktake</p>
          <p className={classes.benefitsItem}>Automated Ordering</p>
          <p className={classes.benefitsItem}>POS Integration*</p>
          <p className={classes.benefitsItem}>Reporting on Sales/Cost/Wastage/ Stocktake/Variance </p>
          <p className={classes.benefitsItem}>Accounting Integrations*</p>
        </div>
        <ThemedButton
          onClick={subscriptionPlan === SubscriptionPlanEnum.HOSPO ? () => {} : openProSuitePurchase}
          title={subscriptionPlan === SubscriptionPlanEnum.HOSPO ? 'Current Plan' : `Upgrade`}
          customClass={classes.contactBtn}
          disabled={subscriptionPlan === SubscriptionPlanEnum.HOSPO || isUpgradeDisabled}
        />
        <ThemedButton
          onClick={() => {
            window.location.href = `https://www.theopenpantry.com/au/rpstidycalbook?app_redirect=${
              process.env.REACT_APP_SELF_URL as string
            }/browse_by_supplier`;
          }}
          title={'Get a Demo'}
          customClass={clsx(classes.contactBtn, classes.demoBtn)}
          buttonStyle='primaryBordered'
          bordered={true}
        />
      </div>
    </div>
  ) : (
    <div className={clsx(classes.root)}>
      <div className={classes.planBox}>
        <div className={classes.planBoxContent}>
          <h3 className={classes.planName}>Free</h3>
          <h4 className={classes.planSubTitle}>Free</h4>
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>Access to:</h4>
          <p className={classes.benefitsItem}>Showcase Entire Product Range</p>
          <p className={classes.benefitsItem}>Unlimited Customers</p>
          <p className={classes.benefitsItem}>Unlimited Orders</p>
          <p className={classes.benefitsItem}>Customer loss reduction tool</p>
          <p className={classes.benefitsItem}>Automatic order reminders to customers</p>
          <p className={classes.benefitsItem}>Automatic notification of back-orders</p>
          <p className={classes.benefitsItem}>Weight & quantity edits</p>
          <p className={classes.benefitsItem}>Receive unlimited Purchase Orders & Create Pick Slips</p>
        </div>
        <ThemedButton onClick={onContactSales} title={`You're Welcome`} width={isMobile ? 268 : 224} buttonStyle='blueButton' />
      </div>
      <div className={clsx(classes.planBox, classes.mostPopularBoxBg)}>
        <div className={classes.mostPopularLabel}>MOST POPULAR</div>
        <div className={classes.planBoxContent}>
          <h3 className={classes.planName}>Unlimited</h3>
          <div className={classes.planSubTitle} />
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>All the Benefits of Free, and:</h4>
          <p className={classes.benefitsItem}>Customised branding & domain (White-label)</p>
          <p className={classes.benefitsItem}>Accounting integrations</p>
          <p className={classes.benefitsItem}>Invoicing & credits</p>
          <p className={classes.benefitsItem}>Order integrations directly to your system</p>
          <p className={classes.benefitsItem}>Unlimited Price Lists</p>
          <p className={classes.benefitsItem}>Unlimited Price Exceptions</p>
          <p className={classes.benefitsItem}>Advanced Delivery options</p>
          <p className={classes.benefitsItem}>Teammates</p>
          <p className={classes.benefitsItem}>Delivery Navigation*</p>
        </div>
        <ThemedButton
          onClick={isUnlimited ? () => {} : onContactSales}
          title={isUnlimited ? 'Current Plan' : 'Get Pricing'}
          customClass={classes.contactBtn}
          disabled={isUnlimited}
        />
      </div>
      <div className={classes.planBox}>
        <div className={classes.planBoxContent}>
          <h3 className={classes.planName}>Enterprise</h3>
          <div className={classes.planSubTitle} />
          <div className={classes.divider} />
          <h4 className={classes.benefitsTitle}>We can build in exactly what you need. Get in touch today!</h4>
        </div>
        <ThemedButton onClick={onContactSales} title='Get Pricing' customClass={classes.contactBtn} />
      </div>
      <OverlayModal
        onClose={setIsContactModalShown.bind(null, false)}
        isOpen={isContactModalShown}
        closeOnClickOutside={true}
        boxClassName={classes.modalRoot}
        contentWrapClassName={classes.modalContentWrap}
      >
        <div className={classes.modalContent}>
          <img src={logo} alt='OpenPantry' width={120} height={22} />
          <img src={subscribe} alt='subscribe' className={classes.subscribeImage} />
          <h2 className={classes.modalTitle}>We will be in touch</h2>
          <span>Call us by Phone</span>
          <ThemedButton onClick={onPhoneClick} title='03 9020 9266' width={280} buttonStyle='blueButton' customClass={classes.phoneBtn} />
          <a href='tel:0390209266' ref={telLink} />
          <span>OR</span>
          <div className={classes.emailBlock}>
            <span>Send email to </span>
            <a href='mailto:info@theopenpantry.com' ref={mailLink}>
              &nbsp;info@theopenpantry.com
            </a>
          </div>
          <ThemedButton onClick={onMailClick} title='Send Email' width={280} buttonStyle='blueButton' />
        </div>
      </OverlayModal>
    </div>
  );
};
